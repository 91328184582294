const matomo = () => {
  window._paq.push(["setCustomUrl", location.pathname]);
  window._paq.push(["setDocumentTitle", location.pathname + " - " + document.title]);
  window._paq.push(["setCookieDomain", "*.express1040.com"]);
  window._paq.push(["setDomains", ["*.express1040.com"]]);
  window._paq.push(["enableCrossDomainLinking"]);
  window._paq.push(["enableLinkTracking"]);
  window._paq.push(["trackPageView"]);
};

export default matomo;