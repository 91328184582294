import getDate from "@/functions/getDate"

const getBannerConfigs = async (configs) => {
  const currentDate = getDate()
  let taxDay = new Date(configs.aprilTaxDeadline)
  configs.daysRemaining = Math.ceil(
    (taxDay.getTime() - currentDate.getTime()) / (1000 * 3600 * 24) - 1
  )
  configs.isBetweenStartEFileAndOctoberDeadline =
    currentDate >= new Date(configs.startEFile) &&
    currentDate <= new Date(configs.octoberTaxDeadline)
  configs.isBeforeStartEFile =
    currentDate < new Date(configs.startEFile)

  return configs
}

export default getBannerConfigs
