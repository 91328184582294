import getDate from "@/functions/getDate";

const getBannerPriceConfigs = async (prices) => {
  const currentDate = getDate();
  const priceChangeDateString = prices.midYearPriceEffectiveDate;
  const priceChangeDate = new Date(priceChangeDateString);
  prices.priceChangeDaysRemaining = Math.ceil(
    (priceChangeDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24) -
      1,
  );

  const proPriceChangeDateString = prices.mid_year_pro_support_effective_date;
  const proPriceChangeDate = new Date(proPriceChangeDateString);
  prices.proPriceChangeDaysRemaining = Math.ceil(
    (proPriceChangeDate.getTime() - currentDate.getTime()) /
      (1000 * 3600 * 24) -
      1,
  );

  return prices;
};

export default getBannerPriceConfigs;
