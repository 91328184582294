const getDate = () => {
  // to time travel test, set the VUE_APP_TIME_TRAVEL variable to true. This will enable date input via url parameters.
  // ex. ?date=2023/10/17 or ?date=2023/10/17:12:30:00 to include time of day
  // this should never be set to true in env.prod, and should by default be true in env.dev and env.test

  const isTimeTravelTest = process.env.VUE_APP_TIME_TRAVEL;
  if (isTimeTravelTest === "true") {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("date")) {
      return new Date(urlParams.get("date"));
    } else {
      return new Date();
    }
  } else {
    return new Date();
  }
};

export default getDate;
